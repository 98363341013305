// ===========================================
// * Fonts
// ===========================================
$font-size-default: 16px;
$font-family-sans: 'Open Sans', sans-serif;
$font-family-serif: 'EB Garamond', serif;

// ===========================================
// * Colors
// ===========================================
$color-text--darker: #111;
$color-text: #333;
$color-text--lighter: #555;
// * Links
$link-color: #144792 !default;
$link-decoration: none !default;
$link-hover-color: #df3d26 !default;
$link-hover-decoration: none !default;

$color-bg-body: #f9f9f9; /* #fafafa; */
$color-filters: #91693c; /* old term color */

// --- Mandala colors
$color-audio-video: #003673;
$color-classical: #ae2012;
$color-collections: #75007d;
$color-images: #ee9b00;
$color-mandala: #4d59ca;
$color-places: #50c878;
$color-subjects: #a0bb7d;
$color-sources: #ab5f11;
$color-texts: #dfcf38;
$color-terms: #1598b5;
$color-visuals: #558e3c;

/* Old Colors:
$color-audio-video: #2ea3ac;
$color-images: #cc761a;
$color-mandala: #4d59ca;
$color-places: #3297f7;
$color-subjects: #cc4c39;
$color-sources: #5a57ad;
$color-texts: #8c48a1;
$color-terms: #91693c;
$color-visuals: #558e3c;
 */

// --- Add-on Theme colors
$color-amber: #a27f1c; // see Card Footer background-color in feature galleries

// * Mapping for future styles
$colors: () !default;
$colors: map-merge(
    (
        'audio-video': $color-audio-video,
        'classical-lit': $color-classical,
        'collections': $color-collections,
        'images': $color-images,
        'mandala': $color-mandala,
        'places': $color-places,
        'subjects': $color-subjects,
        'sources': $color-sources,
        'texts': $color-texts,
        'terms': $color-terms,
        'visuals': $color-visuals,
        'amber': $color-amber,
    ),
    $colors
);

// --------------------------------------
// * Theme colors
$primary: $color-mandala;
$secondary: $color-amber;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        'primary': $primary,
        'secondary': $secondary,
    ),
    $theme-colors
);

$siteBanner-height: 4rem;
$contentBanner-height: 5.5rem;
$pageFooter-height: 9rem;

// ===========================================
// * Breakpoints - SEE BOOTSTRAP variables
// ===========================================
