@font-face {
    font-family: 'shanticon';
    src: url('shanticon.ttf') format('truetype');
    /* url('fonts/shanticon.woff?ob87r4') format('woff'),
    url('fonts/shanticon.svg?ob87r4#shanticon') format('svg');*/
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
.icon,
[class^='u-icon__'],
[class*='u-icon__'],
[class^='shanticon-'],
[class*=' shanticon-'] {
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon::before,
[class^='u-icon__']::before,
[class*='u-icon__']::before,
[class^='shanticon-']::before,
[class*='shanticon-']::before {
    font-family: 'shanticon', sans-serif;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}
.u-icon__agents::before,
.shanticon-agents:before {
    content: '\e600';
}
.u-icon__arrow-left_2::before,
.shanticon-arrow-left_2:before {
    content: '\e601';
}
.u-icon__arrow-left::before,
.shanticon-arrow-left:before {
    content: '\e602';
}
.u-icon__arrow-right_2::before,
.shanticon-arrow-right_2:before {
    content: '\e603';
}
.u-icon__arrow-right::before,
.shanticon-arrow-right:before {
    content: '\e604';
}
.u-icon__arrow3-down::before,
.shanticon-arrow3-down:before {
    content: '\e605';
}
.u-icon__arrow3-left::before,
.shanticon-arrow3-left:before {
    content: '\e606';
}
.u-icon__arrow3-right::before,
.shanticon-arrow3-right:before {
    content: '\e607';
}
.u-icon__arrow3-up::before,
.shanticon-arrow3-up:before {
    content: '\e608';
}
.u-icon__arrowselect::before,
.shanticon-arrowselect:before {
    content: '\e609';
}
.u-icon__audio::before,
.shanticon-audio:before {
    content: '\e60a';
}
.u-icon__logo-shanti::before,
.shanticon-logo-shanti:before {
    content: '\e60b';
}
.u-icon__calendar::before,
.shanticon-calendar:before {
    content: '\e60c';
}
.u-icon__cancel::before,
.shanticon-cancel:before {
    content: '\e60d';
}
.u-icon__check::before,
.shanticon-check:before {
    content: '\e60e';
}
.u-icon__close2::before,
.shanticon-close2:before {
    content: '\e60f';
}
.u-icon__close::before,
.shanticon-close:before {
    content: '\e610';
}
.u-icon__community::before,
.shanticon-community:before {
    content: '\e611';
}
.u-icon__create::before,
.shanticon-create:before {
    content: '\e612';
}
.u-icon__directions::before,
.shanticon-directions:before {
    content: '\e613';
}
.u-icon__disc::before,
.shanticon-disc:before {
    content: '\e614';
}
.u-icon__disc2::before,
.shanticon-disc2:before {
    content: '\e615';
}
.u-icon__download::before,
.shanticon-download:before {
    content: '\e616';
}
.u-icon__editor::before,
.shanticon-editor:before {
    content: '\e617';
}
.u-icon__essays::before,
.shanticon-essays:before {
    content: '\e618';
}
.u-icon__events::before,
.shanticon-events:before {
    content: '\e619';
}
.shanticon-uniE626:before,
.u-icon__explore::before,
.shanticon-explore:before {
    content: '\e61a';
}
.u-icon__grid::before,
.shanticon-grid:before {
    content: '\e61b';
}
.u-icon__hourglass::before,
.shanticon-hourglass:before {
    content: '\e61c';
}
.u-icon__link-external::before,
.shanticon-link-external:before {
    content: '\e61d';
}
.u-icon__list::before,
.shanticon-list:before {
    content: '\e61e';
}
.u-icon__list2::before,
.shanticon-list2:before {
    content: '\e61f';
}
.u-icon__list4::before,
.shanticon-list4:before {
    content: '\e620';
}
.u-icon__lock-open::before,
.shanticon-lock-open:before {
    content: '\e621';
}
.u-icon__lock::before,
.shanticon-lock:before {
    content: '\e622';
}
.u-icon__magnify::before,
.shanticon-magnify:before {
    content: '\e623';
}
.u-icon__map-marker::before,
.shanticon-map-marker:before {
    content: '\e624';
}
.u-icon__menu::before,
.shanticon-menu:before {
    content: '\e626';
}
.u-icon__menu3::before,
.shanticon-menu3:before {
    content: '\e68f';
}
.u-icon__multiply::before,
.shanticon-multiply:before {
    content: '\e628';
}
.u-icon__overview::before,
.shanticon-overview:before {
    content: '\e629';
}
.u-icon__images::before,
.shanticon-images:before {
    content: '\e62a';
}
.u-icon__places::before,
.shanticon-places:before {
    content: '\e62b';
}
.u-icon__play-transcript::before,
.shanticon-play-transcript:before {
    content: '\e62c';
}
.u-icon__play-video::before,
.shanticon-play-video:before {
    content: '\e62d';
}
.u-icon__preview::before,
.shanticon-preview:before {
    content: '\e62e';
}
.u-icon__search::before,
.shanticon-search:before {
    content: '\e62f';
}
.u-icon__share::before,
.shanticon-share:before {
    content: '\e630';
}
.u-icon__sources::before,
.shanticon-sources:before {
    content: '\e631';
}
.u-icon__spin3::before,
.shanticon-spin3:before {
    content: '\e632';
}
.u-icon__collections::before,
.shanticon-collections:before {
    content: '\e633';
}
.u-icon__subjects::before,
.shanticon-subjects:before {
    content: '\e634';
}
.u-icon__terms::before,
.shanticon-terms:before {
    content: '\e635';
}
.u-icon__texts::before,
.shanticon-texts:before {
    content: '\e636';
}
.u-icon__trash::before,
.shanticon-trash:before {
    content: '\e637';
}
.u-icon__tree::before,
.shanticon-tree:before {
    content: '\e638';
}
.u-icon__upload::before,
.shanticon-upload:before {
    content: '\e639';
}
.u-icon__video::before,
.shanticon-video:before {
    content: '\e63a';
}
.u-icon__visuals::before,
.shanticon-visuals:before {
    content: '\e63b';
}
.u-icon__arrow-end-left::before,
.shanticon-arrow-end-left:before {
    content: '\e63c';
}
.u-icon__arrow-end-right::before,
.shanticon-arrow-end-right:before {
    content: '\e63d';
}
.u-icon__arrow-dbl-right::before,
.shanticon-arrow-dbl-right:before {
    content: '\e63e';
}
.u-icon__arrow-dbl-left::before,
.shanticon-arrow-dbl-left:before {
    content: '\e63f';
}
.u-icon__arrow-tri-left::before,
.shanticon-arrow-tri-left:before {
    content: '\e640';
}
.u-icon__arrow-tri-right::before,
.shanticon-arrow-tri-right:before {
    content: '\e641';
}
.u-icon__arrow-tip-left::before,
.shanticon-arrow-tip-left:before {
    content: '\e642';
}
.u-icon__arrow-tip-right::before,
.shanticon-arrow-tip-right:before {
    content: '\e643';
}
.u-icon__arrow-tip-up::before,
.shanticon-arrow-tip-up:before {
    content: '\e644';
}
.u-icon__arrow-tip-down::before,
.shanticon-arrow-tip-down:before {
    content: '\e645';
}
.u-icon__arrow-empty-right::before,
.shanticon-arrow-empty-right:before {
    content: '\e646';
}
.u-icon__row-empty-left::before,
.shanticon-row-empty-left:before {
    content: '\e647';
}
.u-icon__audio-video::before,
.shanticon-audio-video:before {
    content: '\e648';
}
.u-icon__mail::before,
.shanticon-mail:before {
    content: '\e649';
}
.u-icon__googleplus::before,
.shanticon-googleplus:before {
    content: '\e64a';
}
.u-icon__facebook::before,
.shanticon-facebook:before {
    content: '\e64b';
}
.u-icon__twitter::before,
.shanticon-twitter:before {
    content: '\e64c';
}
.u-icon__spinner::before,
.shanticon-spinner:before {
    content: '\e64d';
}
.u-icon__spinner6::before,
.shanticon-spinner6:before {
    content: '\e64e';
}
.u-icon__spinner2::before,
.shanticon-spinner2:before {
    content: '\e64f';
}
.u-icon__zoom-in::before,
.shanticon-zoom-in:before {
    content: '\e650';
}
.u-icon__zoom-out::before,
.shanticon-zoom-out:before {
    content: '\e651';
}
.u-icon__sort-alpha-asc::before,
.shanticon-sort-alpha-asc:before {
    content: '\e652';
}
.u-icon__sort-alpha-desc::before,
.shanticon-sort-alpha-desc:before {
    content: '\e653';
}
.u-icon__sort-amount-asc::before,
.shanticon-sort-amount-asc:before {
    content: '\e654';
}
.u-icon__sort-amount-desc::before,
.shanticon-sort-amount-desc:before {
    content: '\e655';
}
.u-icon__new-tab::before,
.shanticon-new-tab:before {
    content: '\e656';
}
.u-icon__headphones::before,
.shanticon-headphones:before {
    content: '\e657';
}
.u-icon__books::before,
.shanticon-books:before {
    content: '\e658';
}
.u-icon__camera::before,
.shanticon-camera:before {
    content: '\e659';
}
.u-icon__arrow-circle-o-right::before,
.shanticon-arrow-circle-o-right:before {
    content: '\e65a';
}
.u-icon__sign-out::before,
.shanticon-sign-out:before {
    content: '\e65b';
}
.u-icon__star::before,
.shanticon-star:before {
    content: '\e65c';
}
.u-icon__star-o::before,
.shanticon-star-o:before {
    content: '\e65d';
}
.u-icon__star-half-empty::before,
.shanticon-star-half-empty:before {
    content: '\e65e';
}
.u-icon__th::before,
.shanticon-th:before {
    content: '\e65f';
}
.u-icon__cog::before,
.shanticon-cog:before {
    content: '\e660';
}
.u-icon__tags::before,
.shanticon-tags:before {
    content: '\e661';
}
.u-icon__enlarge::before,
.shanticon-enlarge:before {
    content: '\e662';
}
.u-icon__arrows-alt::before,
.shanticon-arrows-alt:before {
    content: '\e663';
}
.u-icon__shrink::before,
.shanticon-shrink:before {
    content: '\e664';
}
.u-icon__arrows::before,
.shanticon-arrows:before {
    content: '\e665';
}
.u-icon__expand::before,
.shanticon-expand:before {
    content: '\e666';
}
.u-icon__compress::before,
.shanticon-compress:before {
    content: '\e667';
}
.u-icon__arrows-h::before,
.shanticon-arrows-h:before {
    content: '\e668';
}
.u-icon__plus::before,
.shanticon-plus:before {
    content: '\e669';
}
.u-icon__minus::before,
.shanticon-minus:before {
    content: '\e66a';
}
.u-icon__minus-square-o::before,
.shanticon-minus-square-o:before {
    content: '\e66b';
}
.u-icon__plus-square-o::before,
.shanticon-plus-square-o:before {
    content: '\e66c';
}
.u-icon__square-o::before,
.shanticon-square-o:before {
    content: '\e66d';
}
.u-icon__check-square-o::before,
.shanticon-check-square-o:before {
    content: '\e66e';
}
.u-icon__comments-o::before,
.shanticon-comments-o:before {
    content: '\e66f';
}
.u-icon__commenting-o::before,
.shanticon-commenting-o:before {
    content: '\e670';
}
.u-icon__file-picture::before,
.shanticon-file-picture:before {
    content: '\e671';
}
.u-icon__image::before,
.shanticon-image:before {
    content: '\e672';
}
.u-icon__address-card-o::before,
.shanticon-address-card-o:before {
    content: '\e673';
}
.u-icon__user-circle-o::before,
.shanticon-user-circle-o:before {
    content: '\e674';
}
.u-icon__user-check::before,
.shanticon-user-check:before {
    content: '\e675';
}
.u-icon__files-empty::before,
.shanticon-files-empty:before {
    content: '\e676';
}
.u-icon__file-o::before,
.shanticon-file-o:before {
    content: '\e677';
}
.u-icon__file-text-o::before,
.shanticon-file-text-o:before {
    content: '\e678';
}
.u-icon__list-alt::before,
.shanticon-list-alt:before {
    content: '\e679';
}
.u-icon__download1::before,
.shanticon-download1:before {
    content: '\e67a';
}
.u-icon__upload1::before,
.shanticon-upload1:before {
    content: '\e67b';
}
.u-icon__eye::before,
.shanticon-eye:before {
    content: '\e67c';
}
.u-icon__eye-blocked::before,
.shanticon-eye-blocked:before {
    content: '\e67d';
}
.u-icon__question-circle-o::before,
.shanticon-question-circle-o:before {
    content: '\e67e';
}
.u-icon__info::before,
.shanticon-info:before {
    content: '\e67f';
}
.u-icon__play2::before,
.shanticon-play2:before {
    content: '\e680';
}
.u-icon__pause::before,
.shanticon-pause:before {
    content: '\e681';
}
.u-icon__stop::before,
.shanticon-stop:before {
    content: '\e682';
}
.u-icon__circle-right::before,
.shanticon-circle-right:before {
    content: '\e683';
}
.u-icon__cancel-circle::before,
.shanticon-cancel-circle:before {
    content: '\e684';
}
.u-icon__envelope-o::before,
.shanticon-envelope-o:before {
    content: '\e685';
}
.u-icon__print::before,
.shanticon-print:before {
    content: '\e686';
}
.u-icon__pencil::before,
.shanticon-pencil:before {
    content: '\e687';
}
.u-icon__edit::before,
.shanticon-edit:before {
    content: '\e688';
}
.u-icon__angle-double-left::before,
.shanticon-angle-double-left:before {
    content: '\e689';
}
.u-icon__angle-double-right::before,
.shanticon-angle-double-right:before {
    content: '\e68a';
}
.u-icon__angle-left::before,
.shanticon-angle-left:before {
    content: '\e68b';
}
.u-icon__angle-right::before,
.shanticon-angle-right:before {
    content: '\e68c';
}
.u-icon__angle-up::before,
.shanticon-angle-up:before {
    content: '\e68d';
}
.u-icon__angle-down::before,
.shanticon-angle-down:before {
    content: '\e68e';
}
.u-icon__kmaps-popover::before,
.shanticon-kmaps-popover:before {
    content: '\e68f';
}
