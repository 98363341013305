/**
    CSS rules for MandalaPopovers, included on all pages by call in App.js
 */
.kmap-tag-group {
    /* position: relative;
    display: inline-block;
    white-space: nowrap; */
    font-style: italic;
}
.kmap-tag-group .deflinks {
    vertical-align: super;
    font-size: 1.3rem;
    margin-top: -1rem;
}

.kmap-tag-group .deflinks a:first-child:before {
    content: '(';
    margin-left: 0.3rem;
}

.kmap-tag-group .deflinks a:after {
    content: ', ';
}

.kmap-tag-group .deflinks a:last-child::after {
    content: ')';
}

.kmap-tag-group .definitions {
    display: inline-block;
    margin-left: 0.2rem;
    margin-right: -0.2rem;
    vertical-align: super;
}

.kmap-tag-group .definitions a:after,
.react-popover-body .deflinks a:after {
    display: inline-block;
    content: ',';
    margin-right: 0.2rem;
}

.kmap-tag-group .definitions a:last-child:after,
.react-popover-body .deflinks a:last-child:after {
    content: '';
    margin-right: 0;
}

.react-popover-body .deflinks a:first-child {
    margin-left: 0.5rem;
}

// icon button for hover-over -- Kmaps Popover
.popover-link {
    position: relative;
    left: 0;
    top: 0.4rem;
    margin: 0 1rem 0 0.2rem;
    /*font-size: 1.75rem;*/
    .u-icon__kmaps-popover::before {
        font-size: 2.25rem;
        margin-left: 0.15rem;
    }
    svg {
        margin-top: -0.85rem;
    }
    .u-icon__sources {
        position: relative;
        top: -0.25rem;
        font-size: 1.7rem;
    }
}
.popover-link .icon::before {
    color: $color-mandala;
}

// BEGIN Popover Container
.related-resources-popover {
    font-size: 1.25rem !important;
    line-height: 1;
    width: 30.2rem !important;
    max-width: 30.2rem !important;
    font-style: normal;
    padding: 0.3rem 0.3rem 0 0.3rem;
    border-radius: 0.8rem !important;
}
.related-resources-popover .popover-title .kmid,
.related-resources-popover .popover-header .kmid {
    font-size: 1.1rem;
    font-weight: 400;
    position: absolute;
    top: 0.3rem;
    right: 0.75rem;
}
.related-resources-popover .react-popover-body .kmdomain,
.related-resources-popover .react-popover-body .kmid {
    display: none;
}
.related-resources-popover .popover-body {
    padding: 0.5rem 0.75rem 0;
    font-size: 1.32rem;
}
.related-resources-popover .popover-body .desc {
    font-size: 1.25rem;
    line-height: 1.35;
    letter-spacing: normal;
    margin-bottom: 1rem;
}
.related-resources-popover .popover-title,
.related-resources-popover .popover-header {
    display: block;
    font-size: 1.4rem;
    padding: 1rem 0 0.6rem;
    margin: 0 1.4rem;
    background: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    color: $gray-800;
    font-weight: 600;
    text-transform: capitalize;
    z-index: 1000;
}

.related-resources-popover .popover-title.bo,
.related-resources-popover .popover-header.bo {
    font-family: Jomolhari;
    font-size: 1.8rem;
}

.related-resources-popover .desc p {
    //   color: $gray-700;
}

.related-resources-popover .parents,
.related-resources-popover .other,
.related-resources-popover .defs {
    //   line-height: 1em;
    //    margin-top: 0.75rem;
}

.related-resources-popover .parents p,
.related-resources-popover .other p,
.related-resources-popover .defs p {
    //   line-height: 1.12em;
    //    margin-bottom: 0;
}

.related-resources-popover .parents strong,
.related-resources-popover .other strong,
.related-resources-popover .defs strong {
    //   color: $gray-800;
    font-size: 1.3rem;
    letter-spacing: 0.0275rem;
    //   margin-top: -0.12em;
}

.related-resources-popover .other strong:after {
    padding-right: 0.3rem;
}

.related-resources-popover .parents strong::after,
.related-resources-popover .other strong::after,
.related-resources-popover .defs strong::after {
    content: ':';
    /*margin-right: 0.3rem;*/
}

.related-resources-popover .parents a,
.related-resources-popover .defs a {
    font-size: 1.25rem;
    line-height: 1.25;
    border-bottom-width: 0;
    display: inline;
    white-space: normal;
    //   color: $gray-700;
}

.related-resources-popover .parents a::after {
    content: ' / ';
}

.related-resources-popover .parents a:last-child::after {
    content: '';
}

.related-resources-popover .parents a:hover,
.related-resources-popover .parents a:active,
.related-resources-popover .defs a:hover,
.related-resources-popover .defs a:active {
    //  color: #cf2f1e;
}

.related-resources-popover .termtypes {
    margin: 0.9rem auto;
}

.related-resources-popover .other.featuretypes a,
.related-resources-popover .termtypes a {
    //   color: #03038e;
    line-height: 1.25;
    font-size: 1.25rem;
}

.related-resources-popover .other.featuretypes a:after,
.related-resources-popover .termtypes a:after {
    content: ', ';
}
.related-resources-popover .other.featuretypes a:last-child:after,
.related-resources-popover .termtypes a:last-child:after {
    content: '';
}
.popover-footer {
    margin: 1rem -0.95rem 0.1rem;
    border-radius: 0 0 0.8rem 0.8rem !important;
}
.popover-footer-button {
    background-color: $gray-900;
    padding: 0 0 0 1.35rem;
    margin: 0;
    font-size: 2rem;
    line-height: 2;
}
.popover-footer-button:hover {
    color: #fff;
    background-color: $color-mandala;
}
.popover-footer-button:active {
    background-color: $gray-800;
}
.popover-footer-button a {
    color: $gray-100;
    border-bottom: none;
    display: block;
    width: 100%;
    font-size: 1.4rem !important;
    line-height: 2;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
    font-family: 'Open Sans', sans-serif;
    padding: 0.6rem 0 0.4rem;
    vertical-align: middle;
}
.popover-footer-button a:hover {
    color: #f0f0f0;
}
.popover-footer-button a.icon::before {
    color: #fff !important;
    margin-right: 1.2rem;
    font-size: 1.5rem;
    position: relative;
    top: 0.1rem;
    left: 0.1rem;
    font-family: 'shanticon', sans-serif;
}
.popover-footer-button:hover .icon,
.popover-footer-button:active .icon {
    color: #fff;
    text-decoration: none;
}
.popover-footer-button a:first-child {
    padding: 0.6rem 0 0.4rem;
    line-height: 2.2;
    font-size: 1.2rem;
}
.popover-footer-button:first-child .icon {
    font-size: 1.2rem;
}
.popover-footer .popover-footer-button:first-child .icon::before {
    margin-right: 1.3rem;
    content: '\e629';
    position: relative;
}
.popover-footer-button:last-child {
    padding-bottom: 0.25rem;
    border-radius: 0 0 0.8rem 0.8rem;
}

.popover-footer .u-icon__link-external:before {
    //   color: #333333;
}

.generic-popover {
    font-size: 1.25rem !important;
    line-height: 1;
    width: 30.2rem !important;
    max-width: 30.2rem !important;
    font-style: normal;
    padding: 0.3rem 0.3rem 0 0.3rem;
    border-radius: 0.8rem !important;

    .popover-header {
        display: block;
        font-size: 1.4rem;
        padding: 1rem 0 0.6rem;
        margin: 0 1.4rem;
        background: transparent;
        border: none;
        border-bottom: 1px solid #ccc;
        color: $gray-800;
        font-weight: 600;
        text-transform: capitalize;
        z-index: 1000;
    }

    .popover-body {
        padding: 0.5rem 0.75rem 0 1.5rem;
        font-size: 1.32rem;
        /*font-size: 1.25rem;*/
        line-height: 1.4;
        letter-spacing: normal;
        margin-bottom: 1rem;
    }
}

.popover-link .u-icon__file-text-o {
    position: relative;
    top: -0.35rem;
}
