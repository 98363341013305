@import 'index-variables';
// @import '~bootstrap/scss/bootstrap';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'fonts/shanticon/style.css';
@import '../views/css/Popover';

html {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    font-family: sans-serif;
    font-size: 62.5%;
}
body {
    font-family: $font-family-sans, sans-serif;
    text-rendering: optimizeLegibility;
    background-color: #f9f9f9;
    font-size: 1.35rem;
}
body.html {
    font-family: $font-family-sans, sans-serif;
    letter-spacing: 0.0125rem;
}
.l-site__wrap a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    border-bottom: 0.075rem solid #fbba03;
    padding-bottom: 0.1rem;
    &:hover {
        text-decoration: none;
        box-shadow: none;
        border-bottom-color: $color-terms;
    }
    &:focus,
    &:active {
        border-bottom-color: transparent;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-weight: 400;
    font-family: $font-family-sans, sans-serif;
}
.bo,
.u-bo {
    font-size: 2rem;
    letter-spacing: normal !important;
    text-rendering: optimizeLegibility !important;
    font-family: 'Babelstone', 'Jomolhari-ID', 'Jomolhari', 'Noto Sans Tibetan',
        'Tibetan Machine Uni', 'Kailasa', 'Uchen_05', 'Microsoft Himalaya',
        sans-serif !important;
}

.sa,
.u-sa {
    font-size: 2rem;
    letter-spacing: normal !important;
    text-rendering: optimizeLegibility !important;
    font-family: 'Noto Serif Devanagari', 'Mangal', 'Siddhanta',
        'Arial Unicode MS', 'Adelle Sans Devanagari', sans-serif !important;
}

.font-sz125 {
    font-size: 125%;
}

.font-sz150 {
    font-size: 150%;
}

.font-sz200 {
    font-size: 200%;
}

.element-invisible {
    display: none;
}

p.rtecenter ~ div.media > div.file-image {
    margin: 0 auto;
}

/**
.l-site__wrap ::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}
.l-site__wrap ::-webkit-scrollbar-track {
    background: transparent;
    border-left: 1px solid #aaa;
}
.l-site__wrap ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #ccc;
}
.l-site__wrap ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
.l-site__wrap ::-webkit-input-placeholder {
    color: #bbb;
}
::placeholder {
    color: #bbb;
}
*/

/*** Inline Styles for HTML from Mandala included in Stand alone ***/
figure.image.center img,
div.image-wrapper.center img,
figure.image.center figcaption,
div.image-wrapper.center figcaption {
    margin-left: auto;
    margin-right: auto;
}

.c-buttonGroup__viewMode .btn-primary:not(:disabled):not(.disabled):active,
.c-buttonGroup__viewMode .btn-primary:not(:disabled):not(.disabled).active,
.c-buttonGroup__viewMode .show > .btn-primary.dropdown-toggle {
    background: #fff;
    color: #000;
    cursor: default;
    border-top-width: 0.2rem;
    border-bottom-width: 0.2rem;
    border-radius: 1rem;
}

.c-buttonGroup__viewMode .btn:not(:disabled):not(.disabled) {
    line-height: 2.5rem;
    width: 4rem;
    margin: 0 0.35rem;
    transform: scale(0.8);
    transition: all 0.3s;
    &:hover {
        border-radius: 1rem;
    }
}

// --- JSON button on kmaps views
.jumbotron {
    background-color: transparent;
}

.sui-hamburger.open {
    display: block;
}
.sui-hamburger.closed {
    display: none;
}

//---------------------------------------
// *** THEME COLORS & ICONS
//---------------------------------------
.icon.white {
    color: #fff;
}
.u-icon__terms::before {
    font-size: 90%;
}
.icon,
[class^='u-icon__'],
[class*='u-icon__'],
[class^='shanticon-'],
[class*='shanticon-'] {
    font-family: 'shanticon', sans-serif;
}

/*  Style for Icons color invert; background-color swap w/text color
 ** background-colors also set below further down
 ** Match "color-invert" with "icon"
 */
.icon.color-invert {
    color: #fff !important;
}

.u-color__all,
.sui-color-all,
.u-color__mandala,
.u-icon__mandala,
.u-icon__audio {
    color: $color-mandala;
}
.u-color__terms,
.sui-color-terms,
.u-icon__terms {
    color: $color-terms;
}
.u-color__audio-video,
.sui-color-audio-video,
.u-icon__audio-video {
    color: $color-audio-video;
}
.u-color__collections,
.sui-color-collections,
.u-icon__collections {
    color: $color-collections;
}
.u-color__images,
.sui-color-images,
.u-icon__images {
    color: $color-images;
}
.u-color__classical,
.sui-color-classical,
.u-icon__classical {
    color: $color-classical;
}
.u-color__sources,
.sui-color-sources,
.u-icon__sources {
    color: $color-sources;
}
.u-color__texts,
.sui-color-texts,
.u-icon__texts {
    color: $color-texts;
}
.u-color__visuals,
.sui-color-visuals,
.u-icon__visuals {
    color: $color-visuals;
}
.u-color__subjects,
.sui-color-subjects,
.u-icon__subjects {
    color: $color-subjects;
}
.u-color__places,
.sui-color-places,
.u-icon__places {
    color: $color-places;
}

// --- temp integration process from prototype
.mandala.all,
.mandala.c-content__header__main,
.u-icon__audio.color-invert {
    background-color: $color-mandala;
}
.mandala.collections,
.u-icon__collections.color-invert {
    background-color: $color-collections;
}
.mandala.places,
.u-icon__places.color-invert {
    background-color: $color-places;
}
.mandala.audio-video,
.u-icon__video.color-invert,
.u-icon__audio-video.color-invert {
    background-color: $color-audio-video;
}
.mandala.classical,
.u-icon__classical.color-invert {
    background-color: $color-classical;
}
.mandala.images,
.u-icon__images.color-invert {
    background-color: $color-images;
}
.mandala.sources,
.u-icon__sources.color-invert {
    background-color: $color-sources;
}
.mandala.terms,
.u-icon__terms.color-invert {
    background-color: $color-terms;
}
.mandala.texts,
.u-icon__texts.color-invert {
    background-color: $color-texts;
}
.mandala.visuals,
.u-icon__visuals.color-invert {
    background-color: $color-visuals;
}
.mandala.subjects,
.u-icon__subjects.color-invert {
    background-color: $color-subjects;
}

// --------------------------------------
// * CONTENT SECTION: LAYOUT
// --------------------------------------
.two-columns {
    display: flex;
    padding: 0 0 0 0.5rem;
}
.l-content__main {
    display: flex;
    padding: 1rem 1.25rem 0 1rem;
    min-height: calc(100vh - 12rem);
}
.l-site__wrap > *,
.l-content__main,
.l-content__main > *,
.c-content__header__main {
    flex: 1;
}
.l-content__main__wrap {
    margin: 0 auto;
}

header.search + .two-columns .l-content__main,
header.collections + .two-columns .l-content__main {
    //  display: block;
}
header.collections + .two-columns .l-content__main > div {
    //  flex:1;
}
// --------------------------------------
// * KMAPS SECTION
// --------------------------------------

header.terms + .two-columns .c-content__main__kmaps > * {
    max-width: 90rem; // asterisk selector is a temporary fix until div wrapper for Terms content is put into place
}
header.terms + .two-columns {
    .c-content__main__kmaps {
        .c-buttonGroup__viewMode__wrap {
            max-width: 100% !important;
        }
    }
}
.custSelect__component {
    margin-bottom: 2rem;
}

.c-content__main__kmaps {
    background-color: #fcfcfc;
    border: 1px solid #ccc;
    border-radius: 1rem;
    padding: 0.5rem 1.5rem 3rem 2rem;
    // font-size: 120%;
    min-height: calc(100vh - 14rem);

    &.c-collection {
        h1.title {
            font-size: 2rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .c-buttonGroup__viewMode {
            top: -45px;
            right: 1rem;
        }
    }

    > * {
        // --- max-width: 90rem;
    }
    > h3 {
        margin-left: 2rem;
        margin-top: 1.75rem;
        font-size: 1.75rem;
    }
    > .nav-tabs {
        margin-top: 1.5rem;
        padding-left: 1.25rem;
        border-bottom: none;
        a {
            font-size: 1.25rem;
            text-transform: uppercase;
            font-weight: 600;
            padding: 1rem 2rem 0.25rem;
        }
    }
    .c-nodeHeader {
        margin-bottom: 1.25rem;
        padding-bottom: 0;
        & > .icon {
            margin: 0 1rem 0 0.3rem;
            position: relative;
            top: 0.3rem;
            left: 0.1rem;
            font-size: 2.5rem;
        }

        .c-node_header__breadcrumbs {
            margin-bottom: 0.5rem;
            .breadcrumb-item {
                border-bottom: none;
                &:hover {
                    border-bottom: thin solid #ddd;
                }
            }
            .breadcrumb-item:before {
                display: none;
                float: none;
            }
            .breadcrumb-item:first-of-type {
                font-weight: 600;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.095rem;
                margin-right: 0;
                margin-left: 1rem;
                &:after {
                    content: ': ';
                }
            }
            .breadcrumb-item:after {
                content: ' /';
            }
            .breadcrumb-item:last-of-type {
                &:after {
                    content: '';
                }
                &:hover {
                    border-bottom: none;
                }
            }

            .c-content__header__perspective {
                display: none;
            }
        }
    }
    .c-nodeHeader__backLink__wrap {
        display: flex;
        position: relative;

        .c-nodeHeader__backLink {
            font-size: 1em;
            font-weight: 600;
            letter-spacing: 0.0275rem;
            color: #4d59ca;
            display: flex;
            font-style: italic;
            .icon::before {
                margin-right: 0.8rem;
                position: relative;
                top: 0.1rem;
            }
        }
    }
}

.c-nodeHeader-itemHeader {
    .c-nodeHeader-itemHeader-subType {
        text-transform: capitalize;
        &::after {
            content: ': ';
        }
    }
    .c-nodeHeader-itemHeader-caption {
        font-style: italic;
    }
    .icon {
        font-size: 1.38em;
        margin-right: 0.75rem;
        position: relative;
        top: 0.35rem;
        color: #fff;
    }
}
/*
.av .c-nodeHeader-itemHeader {
    background-color: $color-audio-video;
    margin-bottom: -1.8rem;
    margin-left: -0.2rem;
}
*/
.c-nodeHeader-itemSummary {
    max-width: 90rem;
    &.nodeHeader-placesInfo {
        display: flex;
        & + div {
            clear: both;
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-bottom: 1rem;

            .nav-tabs {
                padding-left: 0.5rem;
                border-bottom: 0.8rem double $color-places;
                margin: 0 1.5rem;
                border-radius: 0.5rem;

                .nav-link {
                    text-transform: uppercase;
                    border: 0.1rem solid #ccc;
                    border-radius: 1rem 1rem 0 0;
                    letter-spacing: 0.125rem;
                    font-weight: 600;
                    font-size: 1.3rem;
                    padding: 1rem 2.5rem 0.4rem;
                    transition: all 0.3s;
                    &.active,
                    &.active:hover {
                        border-color: #aaa;
                        border-top: 0.3rem solid $color-places;
                        background-color: #fff;
                        margin-top: -0.25rem;
                    }
                    &:hover {
                        border-top-color: #555;
                        background-color: #fff;
                    }
                }
            }
            .tab-content {
                border-color: $color-places;
                border-style: double;
                border-width: 0.8rem 0.5rem 0.8rem 0.5rem;
                border-radius: 1rem;

                #place-kmap-tabs-tabpane-map {
                    padding: 0;
                }

                #place-kmap-tabs-tabpane-names,
                #place-kmap-tabs-tabpane-location,
                #place-kmap-tabs-tabpane-ids {
                    padding: 0.75rem 1.5rem 3rem 2.25rem;
                    font-size: 1.55rem;
                    min-height: 45rem;
                }
            }
        }
    }
    .featured {
        & > img {
            background-color: #fff;
            border-radius: 0.3rem !important;
            padding: 0.8rem;
            box-shadow: 0 0 5px 1px #999 inset;
            margin: 0 2rem 0.5rem 0;
            max-width: 24rem;
        }
        & > p {
            font-size: 1.5rem;
            max-width: 23rem;
            margin: 0 0 1rem 0.85rem;
            line-height: 1.2;
            letter-spacing: 0.0375rem;
            font-family: 'GB Garamond', serif;
        }
    }
    .nodeHeader-summary {
        margin-right: 0;
        margin-left: 1rem;
        padding-top: 0;
    }
    .featureTypes {
        margin-top: -0.8rem;

        label {
            display: inline-block;
            text-transform: uppercase;
            font-weight: 600;

            &::after {
                content: ':';
                margin-right: 1rem;
            }
        }
    }
    &.nodeHeader-subjectsInfo {
        .featured {
            float: left;
            margin: 0;
            img {
                margin: 0 2rem 0.5rem 0;
                max-width: 22rem;
            }
        }
        .nodeHeader-summary {
            margin-right: 0;
        }
    }
}

//
// SEARCH Section ------- move this
//
.l-content__rightsidebar {
    margin: -4.8rem 0 0 0;
    /* max-width: 45rem;
    min-width: calc(max(25vw, 32rem)); */
    font-size: 1.4rem;
    background-color: $color-bg-body;
    resize: horizontal;
    overflow: visible !important;
    border-left: 0.25rem solid #d0d0d0;

    .advanced-search-and-tree {
        position: relative;
        border: none;
        background-color: $color-bg-body;
        height: 100%;
        .nav-tabs {
            padding: 0.5rem 0 0;
            margin: 0;
            border-bottom: none;
            background: $color-bg-body;
            .nav-link {
                padding: 0.5rem;
                margin: 0;
                width: 32%;
                text-align: center;
                border: thin solid #aaa;
                /*border-radius: 0;*/
                border-top-right-radius: 0.5rem;
                border-top-left-radius: 0.5rem;
                /*font-size: 1.6rem;*/
                color: #000; /* $color-mandala; */
                letter-spacing: 0.1rem;
                position: relative;
                cursor: default;
                font-weight: 600;
                transition: all 0.35s;
                text-transform: uppercase;
                &:nth-of-type(2) {
                    width: 35%;
                }
                &:last-of-type {
                    /* border-right: none;*/
                }
                &.active#kmaps-tab-tab-places {
                    background-color: $color-places !important;
                    /*border-color: $color-places;*/
                }
                &.active#kmaps-tab-tab-subjects {
                    background-color: $color-subjects !important;
                    border-color: $color-subjects;
                }
                &.active#kmaps-tab-tab-terms {
                    background-color: $color-terms !important;
                    border-color: $color-terms;
                }
                &.active::before {
                    width: 0;
                    height: 0;
                    content: '';
                    display: none; /* inline-block; */
                    border-style: solid;
                    border-width: 0.1rem 0.85rem 0.85rem 0.85rem;
                    border-color: transparent transparent red;
                    border-bottom-color: inherit;
                    position: absolute;
                    bottom: -0.65rem;
                    left: 50%;
                    margin-left: -0.75rem;
                    background: white;
                }
            }
        }
        .treeNav-header ~ .nav-tabs .nav-link {
            margin-top: 0;
            padding-bottom: 0.25rem;
            border-bottom: none !important;
        }
    }
}
.closeSideBar.l-content__rightsidebar,
.closeSideBar .l-content__rightsidebar {
    // display: none;
}

// All perspective selects in aside (right and left)
.c-perspective-select {
    label {
        display: block;
    }
    form,
    select {
        max-width: 18rem;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
        display: inline-block;
    }
}

.l-column__search {
    height: 100%;
    min-height: 80rem;
    font-size: 1.3rem;
    z-index: 1000;
    padding-right: 1rem;
    background: #fff;
    border-top: 0.6rem double #4d59ca;
    min-height: 100vh;

    p {
        margin: 0.75rem auto -0.45rem;
        text-align: center;
        font-style: italic;
        font-size: 1.4rem;
    }
    pre {
        text-align: center;
    }
    &.c-TreeNav--tabs {
        padding-right: 0;
        &.open {
            z-index: 1000;
            //  background: #eee;
            height: 100%;
        }
    }
    &.open.standalone {
        z-index: inherit;
        flex: inherit;
        position: relative;
        top: inherit;
        right: inherit;
        width: inherit;
        max-width: inherit;
        background: inherit;
        border-left: none;
    }
    .c-perspective-select {
        display: inline-block;
    }
    .sui-advFooter {
        margin-top: 1.5rem;
        text-align: center;
    }
    .treeNav-header {
        display: flex;
        justify-content: space-between;
        margin: 0 0 0.15rem 0;
        font-size: 1.45rem;
        font-weight: 600;
        letter-spacing: 0.0525rem;
        background-color: #3f49b1;
        border-bottom: 0.6rem double #fff;
        height: 4.2rem;

        .treeNav-header__title {
            margin-left: 2.5rem;
            color: #eee;
            font-weight: 400;
            padding: 0;
            line-height: 1.475;
            font-size: 2.35rem;
            letter-spacing: 0.075rem;
            font-family: 'GB Garamond', serif;
        }
        .treeNav-header__closeButton {
            padding: 0;
            margin: 0;
            border: none;
            cursor: default;
            background: transparent;
            font-size: 1.75rem;
            position: relative;
            right: 2.25rem;
            bottom: -0.1rem;
            transition: all 0.2s;
            transform: scale(1.25);
            opacity: 0.5;
            .icon::before {
                color: #fff;
            }
            &:hover {
                opacity: 0.8;
                transform: scale(1.1);
            }
            &:active {
                transform: scale(1.25);
                border: none;
            }
        }
    }
    .search-column-header-filters {
        position: relative;
        background-color: #3f49b1;
        margin-right: -1rem;
        transition: opacity 0.35s ease-in-out;
        border-bottom: 0.6rem double #fff;
        display: flex;
        justify-content: space-between;
        padding-right: 2rem;
        height: 4.2rem;

        h4 {
            margin-left: 2.5rem;
            color: #eee;
            padding: 0;
            line-height: 1.7;
            font-size: 2.15rem;
            letter-spacing: 0.075rem;
            font-weight: 400;
            font-family: 'GB Garamond', serif;

            .header-label-count {
                font-size: 1.15rem;
                letter-spacing: 0.125rem;
                margin-left: 0.25rem;
                font-weight: 700;
                text-transform: uppercase;
                position: relative;
                bottom: 0.1rem;
                border: none;
                font-family: 'Open Sans', sans-serif;
                .badge {
                    font-size: 1.35rem;
                    letter-spacing: 0.1rem;
                    color: #fff;
                    background: transparent;
                    margin: 0 0 0 1rem;
                    padding: 0.35rem 0.65rem;
                    transition: all 0.4s ease-in-out;
                    border-left: 1px solid #fff;
                    border-right: 1px solid #fff;
                    border-radius: 1.25rem;
                    position: relative;
                    bottom: 0.05rem;
                }
            }
        }

        /* CLOSE - Search Column -- Filters */
        .search-column-close-filters {
            padding: 0;
            margin: 0.2rem 0.25rem 0 0;
            border: none;
            cursor: default;
            background: transparent;
            font-size: 1.75rem;
            transition: all 0.2s;
            -webkit-transform: scale(1);
            transform: scale(1.25);
            opacity: 0.5;
            .icon::before {
                color: #fff;
            }
            &:hover {
                opacity: 0.8;
                transform: scale(1.1);
            }
            &:active {
                transform: scale(1.25);
                border: none;
            }
        }
    }
    /* RESET Filters */
    .search-column-reset-filters {
        margin: 0.5rem 0.5rem 0 0;
        padding: 0;
        display: flex;
        justify-content: center;

        button,
        .back-to-results {
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 2;
            letter-spacing: 0.075rem;
            margin: 0 0.35rem;
            padding: 0 1.75rem;
            background-color: #a27f1c;
            color: #fff;
            border: 4px double #fff;
            border-radius: 1.8rem;
            opacity: 0.9;
            transition: all 0.2s;
            text-transform: uppercase;
            transform: scale(1);

            &:hover {
                border: 4px double #fff;
                color: #fff;
                opacity: 1;
            }
            &:active {
                border: 4px double #fff;
                transform: scale(0.98);
            }
            .header-icon .icon {
                font-size: 1.3rem;
                color: #fff;
                margin: 0 0.75rem 0 0.15rem;
                position: relative;
                bottom: -0.1rem;
            }
        }

        .back-to-results {
            background-color: #4d59ca;
            padding: 0.15rem 1.75rem 0.15rem 1.25rem;
        }
    }
}
.search + .two-columns .search-column-reset-filters {
    justify-content: space-evenly;
}
.search + .two-columns .search-column-reset-filters button:last-child {
    position: relative;
    left: 1rem;
    padding-right: 3.5rem;
    padding-left: 3.5rem;
}
.u-search__noresults__wrap {
    width: 75vw;
    text-align: center;

    .u-search__noresults {
        display: block;
        .u-search__noresults__header {
            font-size: 3rem;
        }
        p {
            font-size: 1.5rem;
        }
        button {
            font-size: 1.2rem;
            font-weight: 600;
            letter-spacing: 0.075rem;
            margin: 0 0.35rem;
            padding: 0 1.75rem;
            border: 1px solid #ddd;
            border-radius: 1.8rem;
            background-color: #a27f1c;
            color: #fff;
            border: 4px double #fff;
            opacity: 0.9;
            transition: all 0.2s;
            text-transform: uppercase;
            height: 3.25rem;
            line-height: 1.5rem;
            transform: scale(1);

            &:hover {
                cursor: pointer !important;
            }
        }
    }
}
/*
 * RESIZE handle for RightSideBar
 */
.resize-right-column {
    background: transparent;
    flex: 0 0 0.5rem;
    div {
        background-color: transparent;
        cursor: col-resize !important;
        z-index: 100 !important;
        &::before {
            height: calc(100vh - 6.75rem);
            position: relative;
            left: -1rem;
            top: -4.5rem;
            display: inline-block;
            content: '';
            width: 1.5rem;
            border-color: #4d59ca;
            border-top-color: #fff;
            border-style: solid;
            border-width: 4rem 0 8rem 0.1rem;
            border-radius: 0.2rem;
            opacity: 0.25;
            transition: all 0.35s;
        }
    }
    &:hover div::before {
        opacity: 0.6;
        border-left-width: 0.1rem;
        border-left-style: solid;
        background-color: #fff;
        background-size: 0.475rem 0.475rem;
        background-image: repeating-linear-gradient(
            0deg,
            #444cf7,
            #444cf7 0.1rem,
            #e5e5f7 0.1rem,
            #e5e5f7
        );
    }
    &:active div::before {
        opacity: 0.75;
        border-left-color: #929ae4;
        background-size: 0.325rem 0.325rem;
        border-width: 4rem 0.1rem 4rem 0.1rem;
    }
}

.sui-nameEntry-meta {
    margin-top: 0.75rem;
}

.u-search__results__wrap {
    width: 100vw;
    text-align: center;
}
.u-search__results__header {
    font-size: 1.6rem;
    white-space: nowrap;
    margin-top: 3rem;
}

// gallery display buttons for view options
.c-buttonGroup__viewMode__wrap {
    padding: 2rem 0.5rem 4rem 0.5rem;
    border: 0.1rem solid #ccc;
    border-radius: 1rem;
    margin-bottom: 3rem;
    position: relative;
    min-height: calc(100vh - 50rem);
    background: #fff;
}
// View mode for content column
.c-buttonGroup__viewMode {
    margin: 0 1.5rem 0 0;
    position: absolute;
    top: -6.25rem;
    right: -1rem;

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active {
        transform: scale(1.04);
    }
}

body.mandala .c-buttonGroup__viewMode {
    top: -5rem;
}

.c-buttonGroup__viewMode__wrap .c-buttonGroup__filter {
    position: absolute;
    right: 200px;
    top: -45px;

    input {
        border-radius: 8px;
        border-color: #ccc;
    }
}

/*header.terms + .two-columns .c-buttonGroup__viewMode,*/
header.search + .two-columns .c-buttonGroup__viewMode {
    position: relative;
    top: 0;
    left: 0;
    padding-right: 2rem;
    width: 100%;
    text-align: right;
    margin-bottom: 1rem;
}
/*
header.terms + .two-columns .c-buttonGroup__viewMode {
    position: relative;
    top: 0;
    padding-right: 1rem;
}
*/
.sui-facetList-horiz {
    margin: 1rem 2rem 2rem;
    padding: 1.4rem;
    transition: all 0.25s;
    border-radius: 0.75rem;
    border: 0.1rem solid rgba(77, 89, 201, 0.5);
    border-left: 0.3rem solid #6b77ea;
    border-right: 0.3rem solid #6b77ea;
    background-color: #f9f9f9;
    color: $color-filters;
    font-weight: 600;
    > span {
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.0575rem;
        text-transform: uppercase;
        margin-left: 0.5rem;
        color: #333;
    }
    .adv-srch-filter-wrapper {
        display: flex;
        padding: 0;
        justify-content: space-between;
        .adv-srch-edit {
            a {
                text-decoration: none;
                border-bottom: none;
                svg {
                    stroke: #91693c;
                }
            }
        }
    }
    .adv-srch-filter-list {
        display: flex;
        flex-wrap: wrap;
        gap: 2.4rem;
        text-transform: capitalize;
        align-items: baseline;
        padding: 0.5rem;
    }
    > div {
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
        padding-right: 1.75rem;
        padding-left: 2.5rem;
        text-transform: capitalize;
        align-items: baseline;
        > span.icon {
            top: 0.2rem !important;
        }
        .facetItem {
            font-size: 1.8rem;
            margin-left: 0.25rem;
            position: relative;
            top: 0;
        }
        .facetItem:not(.icon) {
            color: $color-mandala;
        }
    }
    &:empty {
        border: none;
        padding: 0;
    }
    > div:first-of-type .facetItem {
        margin-left: 0.5rem;
    }
}

.c-buttonGroup__viewMode-header {
    font-size: 1.25rem;
    margin-right: 0.8rem;
    white-space: nowrap;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2.5;
    letter-spacing: 0.025rem;
    display: none;
}
.c-buttonGroup__viewMode .icon::before {
    font-size: 1.5rem;
    position: relative;
    top: 0.35rem;
}

.c-buttonGroup__viewMode .active .icon {
    border-color: $color-mandala;
}
.c-buttonGroup__viewMode .active .icon::before {
    color: $color-mandala;
}

.c-buttonGroup__sortMode {
    position: absolute;
    top: -40px;
    right: 400px;
    z-index: 10;
    * {
        display: inline-block;
    }

    select {
        margin-right: 1rem;
        border: none;
    }
}

.mandala .c-buttonGroup__sortMode {
    top: -50px;
}

.c-buttonGroup__sortMode-header {
    font-size: 1.25rem;
    margin-right: 0.8rem;
    white-space: nowrap;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2.5;
    letter-spacing: 0.025rem;
}

// View Styles

.c-view__wrapper {
    /*   border: none;
    padding-top: 2.5rem;
    background: #fff;
    margin: 1rem 1.5rem;
    border: 1px solid #ccc;
    border-radius: 1rem; */
}
.c-view__wrapper.gallery {
    margin: 0 1.5rem 2rem;
    padding-top: 0;
    border: none;
}
.react-photo-gallery--gallery,
.react-photo-gallery--gallery div,
.react-photo-gallery--gallery img {
    /*cursor: default !important;*/
}

header.terms + .two-columns .c-view__wrapper.gallery {
    margin-top: 0;
}

.c-buttoneGroup__viewMode {
    display: inline-block;
    position: relative;
    top: 3.5rem;
    left: 4.5rem;
}

// List View Only

.list .c-view {
    margin: 0 1rem 0 0.5rem;
    .card {
        /* border: unset; */
        border-color: #cde3f7;
        border-width: 0.1rem 0 0;
        background-color: #fff;
        margin-left: 0.75rem !important;
    }
    .u-icon__plus:before {
        color: $color-amber;
        opacity: 0.4;
        display: inline-block;
        margin-right: 0.5rem;
        margin-left: -1.5rem;
        padding: 0;
        font-size: 1.6rem;
        position: relative;
        top: 0.1rem;

        &:hover {
            opacity: 0.8;
        }
        &.open,
        &.openitem {
            transform: rotate(45deg);
            opacity: 1;
        }
    }
    .audio-video .u-icon__plus:before {
        color: $color-audio-video;
    }
    .classical .u-icon__plus:before {
        color: $color-classical;
    }
    .collections .u-icon__plus:before {
        color: $color-collections;
    }
    .images .u-icon__plus:before {
        color: $color-images;
    }
    .places .u-icon__plus:before {
        color: $color-places;
    }
    .sources .u-icon__plus:before {
        color: $color-sources;
    }
    .subjects .u-icon__plus:before {
        color: $color-subjects;
    }
    .terms .u-icon__plus:before {
        color: $color-terms;
    }
    .texts .u-icon__plus:before {
        color: $color-sources;
    }
    .visuals .u-icon__plus:before {
        color: $color-visuals;
    }
    .u-icon__plus.open:before {
        transform: rotate(45deg);
        opacity: 1;
    }

    .card .type.icon {
        font-size: 1.75rem;
        padding: 0.54rem;
        border-radius: 0.5rem;
        margin-right: 0.25rem;
        background-color: #fff;
        padding-left: 1.8rem;

        ~ .u-icon__plus {
            padding-left: 0;
        }

        &.shanticon-audio-video {
            color: $color-audio-video;
        }
        &.shanticon-images {
            color: $color-images;
        }
        &.shanticon-sources {
            color: $color-sources;
        }
        &.shanticon-texts {
            color: $color-texts;
        }
        &.shanticon-visuals {
            color: $color-visuals;
        }
        &.shanticon-places {
            color: $color-places;
        }
        &.shanticon-subjects {
            color: $color-subjects;
        }
        &.shanticon-terms {
            color: $color-terms;
        }
    }

    .title {
        padding-top: 0.5rem;
        line-height: 2.5rem;
        font-size: 1.6rem;
        .header {
            color: #333;
            font-weight: 600;
            .subtitle {
                display: inline-block;
                margin-left: 0.5rem;
            }
            span.bo {
                font-weight: normal;
            }
        }
    }

    .title span + .subtitle::before {
        content: '>';
        margin-right: 0.5rem;
    }

    .meta {
        font-size: 1.5rem;
        font-style: italic;
        text-align: right;
    }

    .meta > span {
        display: block;
    }

    .card-body {
        max-height: unset;
        min-height: 3rem;
        font-size: 1.75rem;
        /*  min-width: 40vw;*/
        background-color: #fff;
        overflow: visible;
        padding: 0.25rem 2rem 0.5rem !important;
        .shanticon-audio-video::before {
            position: relative;
            left: 0.1rem;
            top: 0.2rem;
            padding: 0;
        }
        .icon {
            margin-right: 0.3rem;
        }
        .icon::before {
            padding-right: 0 !important;
            top: 0.15rem;
            position: relative;
        }
        .icon.u-icon__collections::before {
            font-size: 1.35rem;
            position: relative;
            top: 0.2rem;
        }
        span.img {
            float: left;
        }
        img {
            background-color: #fff;
            border-radius: 0.3rem !important;
            padding: 0.8rem;
            box-shadow: 0 0 5px 1px #999 inset;
            margin: 0 1rem 1rem 1rem;
            max-width: 150px !important;
        }
        .info {
            padding-top: 0.75rem;
            margin-left: 4rem;
            font-size: 1.5rem;
        }
        .creator {
            display: block;
            margin: 0.3rem 0;
            position: relative;
            left: 0.25rem;
            .icon {
                position: relative;
                left: -0.25rem;
            }
        }
        .source-authors {
            margin: 0.5rem 0 0 4rem;
            font-family: 'EB Garamond', serif;
            font-size: 1.75rem;
            color: #111;
            letter-spacing: 0.0275rem;
            font-style: italic;
            .shanticon-agents {
                color: $color-sources;
                font-size: 1.5rem;
            }
        }
        /*
        .citation {
            background-color: #ededed;
            margin-top: 0.5rem;
            padding: 0.5rem 2rem;
            width: fit-content;
        }*/
        p {
            max-width: 93%;
        }
        .kmap-container {
            clear: both;
            .kmaps {
                max-width: 31%;
                min-width: 27rem;
                float: left;
                h5 {
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #555;
                    margin-left: -0.75rem;
                }
                span.icon {
                    display: inline-block;
                    margin-right: 0.5rem;
                    font-size: 1.5rem;
                }
                ul {
                    margin-left: 0;
                    /*padding-inline-start: 1.5rem;*/
                }
                li {
                    list-style-type: none;
                    font-family: 'GB Garamond', serif;
                    font-size: 1.75rem;
                }
            } // Ends kmaps for relateds in accordion
        }
    } // Ends card-body in List View

    // Kmaps card styles in List View
    .card.places,
    .card.subjects,
    .card.terms {
        margin-top: 0;
        margin-bottom: 0;
    }

    // Sources in List View
    .sources.card .info .img {
        display: none;
    }

    .feature-types span {
        font-size: 1.5rem;
        font-style: italic;
        color: #000;
    }
    .feature-types span::before {
        width: 0.6rem;
        height: 0.6rem;
        content: '';
        display: inline-block;
        position: relative;
        bottom: 0.15rem;
        margin-right: 0.7rem;
        margin-left: 0.7rem;
        background-color: #63a5f4;
        filter: alpha(opacity=85);
        opacity: 0.85;
    }
    .ancestors {
        font-size: 1.35rem;
    }
    // Ancestors (and captions) for kmaps in list view
    .ancestors,
    .caption {
        margin-left: 3rem;
        span {
            display: inline-block;
            margin-left: 0.25rem;
            margin-right: 0.25rem;
        }
        span:first-child {
            margin-left: 0;
        }
        span::after {
            content: '>';
            margin-left: 0.5rem;
        }

        span:last-child::after {
            content: '';
        }
    }

    .collapse .info.kmaps,
    .collapsing .info.kmaps,
    .info.kmaps {
        p.caption {
            margin-bottom: 0;
            font-style: italic;
        }
        div.altnames {
            margin: 0 0 0 1rem;
            font-size: 1.25rem;
            .altname {
                display: inline-block;
                margin-right: 0.5rem;
                &:after {
                    content: ', ';
                }
                &:last-of-type:after {
                    content: '';
                }
            }
            .bo {
                font-size: 1.9rem;
            }
        }
    }
} // End list view styles

// Asset Home Pages
.assethome {
    padding-top: 0.5rem;
    & > .desc {
        font-size: 1.5rem;

        & > * {
            margin: 0 2rem 1rem 1rem;
        }
    }

    .c-buttonGroup__viewMode__wrap {
        width: 70vw;
        justify-content: right !important;
        margin-top: -5.5rem !important;
        margin-bottom: 1rem !important;
        background: transparent !important;

        .c-buttonGroup__viewMode {
            top: -1rem !important;
        }
    }
}

// Not Found Page for Assets
.c-not-found {
    text-align: center;
    font-size: 1.75rem !important;

    .logo {
        margin: 1rem 0 2rem 40%;
        max-height: 13vh;
        opacity: 0.35;
    }
    h1 {
        text-transform: capitalize;
        margin-bottom: 1rem;
    }

    p {
        font-style: italic;
        line-height: 3rem;
    }

    button,
    a.btn {
        color: lightgrey;
        font-size: 1.5rem;
        padding: 0.25rem 1.5rem;
        border-radius: 2rem;
    }
}

@media screen and (max-width: 950px) {
    .list .c-view .ancestors {
        white-space: normal;
        line-height: 1.5rem;
        font-size: 1.1rem;
    }
}

// Dumping this for now here -
main.subjects .shanti-texts-section-title.text-level-3,
main.subjects .shanti-texts-section-title.text-level-4,
main.subjects .shanti-texts-section-title.text-level-5,
main.subjects .shanti-texts-section-title.text-level-6,
main.subjects .shanti-texts-section-title.text-level-7 {
    font-weight: 400 !important;
    padding: 0 !important;
    color: #333 !important;
}

.shanti-texts-section-content h1 {
    margin-top: 3rem;
}

span.refdate {
    &::before {
        content: ' (';
        padding-left: 0.5rem;
    }
    &::after {
        content: ')';
    }
}

.c-content__main__kmaps .l-site__wrap.texts #shanti-texts-sidebar .tab-content {
    height: calc(100vh - 28.5rem);
}

.c-content__main__kmaps .l-site__wrap.texts #shanti-texts-body {
    height: calc(100vh - 24rem);
}

// Places in Kmaps Mapping Zoom buttons top-left
.ol-viewport {
    button {
        cursor: pointer;
        font-size: 3rem;
        font-weight: 600;
        color: #555;
        margin: 0.75rem;
        border-radius: 0.8rem;
        background: #fff;
        border: 6px double #555;
        display: inline-block;
        padding: 0 1rem;
        transition: all 0.25s;
        &:hover {
            background: $color-places;
            color: #fff;
            transform: scale(0.9);
            border-color: #fff;
        }
        &:active,
        &:focus {
            transform: scale(1);
        }
    }
    .ol-rotate,
    .ol-attribution,
    .ol-zoomslider,
    .ol-scale-line {
        display: none;
    }
}

.sicon-ref-page {
    .sicon-ref-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        max-width: 80vw;

        .sicon-item {
            width: 20%;
            min-width: 30px;
            max-width: 90px;
            padding: 0.5rem;
            border: thin solid green;
            text-align: center;
            span.icon {
                font-size: 3rem;
            }
            p.label {
                margin: 0;
                padding: 0;
                font-weight: 600;
            }
        }
    }
}

.sicon-tooltip .tooltip-inner {
    padding: 1rem;
    font-size: 1.5rem;
    max-width: 100px;
    margin-left: -5px;
    height: 120px;
    padding-top: 3rem;
    background-color: #555;
}

.mndl-access {
    &.logout * {
        color: green;
        font-size: 1.75rem;
        border: none;
    }
    &.login {
        * {
            font-size: 1.5rem;
            border: none;
            margin-top: -0.2rem;
        }
        .btn {
            background-color: #3f49b1;
        }
    }
}
